import { createContext, FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import AuthContext from './AuthContext';

export interface RewardCalendarContextProps {
    isOpenRewardModal: boolean,
    setIsOpenRewardModal: (state: boolean) => void,
}

const RewardCalendarContext = createContext<RewardCalendarContextProps>({} as RewardCalendarContextProps);
interface IAuthContextProviderProps {
    children: ReactNode;
}

export const RewardCalendarContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
    const { accessToken } = useContext(AuthContext);
    const [isOpenRewardModal, setIsOpenRewardModal] = useState<boolean>(false);

    useEffect(() => {
        if(accessToken)
        {
            setIsOpenRewardModal(true);
        }
    }, [accessToken]);

    useEffect(() => {
        setIsOpenRewardModal(isOpenRewardModal);
    }, [isOpenRewardModal]);

    const value = useMemo(
        () => ({
            setIsOpenRewardModal,
            isOpenRewardModal,
        }),
        [isOpenRewardModal]
    );
    return <RewardCalendarContext.Provider value={value}>{children}</RewardCalendarContext.Provider>;
};

export default RewardCalendarContext;