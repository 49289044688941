import { useContext, useEffect } from "react";
import LoginTab from "../_layouts/Modals/LoginTab";
import AuthContext from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const navigate = useNavigate();
    const { accessToken } = useContext(AuthContext);

    useEffect(() => {
        if(accessToken)
        {
            navigate('/');
        }
    }, [accessToken]);

    return (
        <div className="container max-w-[1100px] mx-auto px-3">
            <div className="grid grid-cols-12 gap-2">
                <div className={`col-span-12 lg:col-span-12`}>
                    <div className="bg-white rounded-lg shadow-lg p-3">
                        <LoginTab />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;