import { useContext, useEffect, useState } from "react";
import { GameType, ProviderType } from "../../../types/Games";
import Button from "../../_tailwild/Button";
import GamesContext from "../../../contexts/GamesContext";
import AuthContext from "../../../contexts/AuthContext";
import LoginContext from "../../../contexts/LoginContext";
import { ApiService } from "../../../services/api.service";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useTranslation } from "react-i18next";


interface PropsType {
    category_name: string;
}

const HomeGame = ({ category_name }: PropsType) => {
    const { t }: { t: (key: string) => string } = useTranslation();
    //const { t } = useTranslation();


    const { games } = useContext(GamesContext);
    const { accessToken } = useContext(AuthContext);
    const { setActiveTab, setLoginModalIsOpen } = useContext(LoginContext);
    const [gettingStartGameUrl, setGettingStartGameUrl] = useState<boolean>(false);

    const startGame = async (game_id: string, provider: string, tab: string) => {
        if(!accessToken)
        {
            setActiveTab('login');
            return setLoginModalIsOpen(true);
        }

        if(gettingStartGameUrl)
        {
            return;
        }

        setGettingStartGameUrl(true);
        try {
            const service = new ApiService();
            let requestGetRedirect = await service.getRedirectGameUrl(accessToken, game_id, provider, tab);

            if(requestGetRedirect.data.status_code !== 200)
            {
                // requestGetRedirect = await service.getRedirectLoginUrl(cookies.get('access_token'));
            }

            const userAgent = navigator.userAgent.toLowerCase();
            const safari = /safari/.test(userAgent) && !/chrome/.test(userAgent);

            if(safari)
            {
                window.location.href = requestGetRedirect.data.url;
            }
            else
            {
                const link = document.createElement('a');
                link.href = requestGetRedirect.data.url;
                link.target = '_blank';
                link.click();
            }
        } catch (err) {
            console.error(err);
        } finally {
            setGettingStartGameUrl(false);
        }
    }

    const createRedirectSports = async () => {
        if(!accessToken)
        {
            setActiveTab('login');
            return setLoginModalIsOpen(true);
        }

        setGettingStartGameUrl(true);
        try {
            const service = new ApiService();
            let getRedirectGameUrl = await service.getRedirectLoginUrl(accessToken);

            const userAgent = navigator.userAgent.toLowerCase();
            const safari = /safari/.test(userAgent) && !/chrome/.test(userAgent);

            const urlObj = new URL(getRedirectGameUrl.data.url);
            const domain = `https://${urlObj.hostname}`;
            const params: { [key: string]: string } = {};
            urlObj.searchParams.forEach((value, key) => {
                params[key] = value;
            });
            const sportsUrl = `${domain}/sports/?uuid=${params.uuid}`;
            if(safari)
            {
                window.location.href = sportsUrl;
            }
            else
            {
                const link = document.createElement('a');
                link.href = sportsUrl;
                link.target = '_blank';
                link.click();
            }
        } catch (err) {
            console.error(err);
        } finally {
            setGettingStartGameUrl(false);
        }
    }

    return (
        <div className="grid grid-cols-12 gap-1 mt-2">
            {games.filter((game: GameType) => game.gameCategory === category_name && (category_name === 'SLOT' ? game.provider === 'PGS' : (
                category_name === 'CASINO' ? (game.provider === 'SEX' || game.provider === 'SAG') : (
                    category_name === "SBO" ? (
                        game.gameName.toLowerCase().includes("SportsBook".toLowerCase()) ? true : false
                    ) : true
                )
            )))
                .sort((a, b) => {
                    if(a.sort === null) return 1;
                    if(b.sort === null) return 0;
                    return a.sort - b.sort;
                })
                .slice(0, 6)
                .map((game: GameType, index: number) => (
                <div
                    className="col-span-4 lg:col-span-2 relative group mb-10"
                    key={`${index}-game-${category_name}-${game.provider_provider}-${game.gameName.replace(/ /g, "_").toLowerCase()}`}
                >
                    <LazyLoadImage
                        className="rounded-lg w-full h-full object-cover"
                        wrapperClassName="rounded-lg w-full h-full object-cover"
                        src={game.image_vertical} 
                        effect="blur"
                    />
                    <div>
                        <p className="text-md leading-none whitespace-nowrap overflow-hidden text-ellipsis">
                            {
                                game.gameName
                            }
                            
                        </p>
                        <p className="text-sm text-gray-400 leading-none">
                            {
                                game.provider_name ? game.provider_name : game.provider
                            }
                        </p>
                    </div>
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg">
                        <Button
                            onClick={() => {
                                if(category_name === 'SBO' && game.gameName.toLowerCase().includes("SportsBook".toLowerCase()))
                                {
                                    return createRedirectSports();
                                }
                                startGame(game.game_id, game.provider, game.gameCategory)
                            }
                        }
                            isDisable={gettingStartGameUrl}
                        >
                            {t("EnterGame")}
                        </Button>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default HomeGame;
