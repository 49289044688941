import ReactDOM from 'react-dom';
import { FC, ReactNode } from 'react';

interface IPortalProps {
	children: ReactNode;
	id?: string;
}
// @ts-ignore
const Portal: FC<IPortalProps> = ({ id = 'portal-root', children }) => {

	// @ts-ignore
	const mount = document.getElementById(id);
	if (mount) return ReactDOM.createPortal(children, mount);
	return null;
};

export default Portal;
