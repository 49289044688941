import { useContext, useEffect, useState } from "react";
import { ApiService } from "../../../services/api.service";
import { ListPromotionsType } from "../../../types/Promotions";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Modal from "../../_utils/Modal";
import DangerouslySetInnerHTML from "../../_utils/DangerouslySetInnerHTML";
import AuthContext from "../../../contexts/AuthContext";
import Button from "../../_tailwild/Button";
import showNotification from "../../_utils/showNotification";
import { useTranslation } from "react-i18next";
import { GiftsListType } from "../../../types/Gifts";
import { useFormik } from "formik";

const Gifts = () => {
    const { t }: { t: (key: string) => string } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const { accessToken } = useContext(AuthContext);
    const [selectedGifts, setSelectedGifts] = useState<GiftsListType | null>(null);
    const [showPromotion, setShowPromotion] = useState<boolean>(false);

    const [getting, setGetting] = useState<boolean>(false);
    const [gifts, setGifts] = useState<GiftsListType[]>([]);

    useEffect(() => {
        getListGifts();
    }, []);

    useEffect(() => {
        if(!selectedGifts)
        {
            setSelectedGifts(null);
        }
    }, [showPromotion])

    const getListGifts = async () => {
        if(loading)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const requestGetContinuousDeposit = await service.getListGifts();

            if(requestGetContinuousDeposit.data.status_code === 200)
            {
                setGifts(requestGetContinuousDeposit.data.list);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    const formik = useFormik({
        initialValues: {
            address: '',
            moo: '',
            khet: '',
            kwang: '',
            province: '',
            zipcode: ''
        },
        validate: (values) => {
            const errors: {
                address?: string;
                moo?: string;
                khet?: string;
                kwang?: string;
                province?: string;
                zipcode?: string;
            } = {};

            if(selectedGifts && selectedGifts.need_address)
            {
                if (!values.address) {
                    errors.address = t("Required");
                }

                if (!values.moo) {
                    errors.moo = t("Required");
                }

                if (!values.khet) {
                    errors.khet = t("Required");
                }

                if (!values.kwang) {
                    errors.kwang = t("Required");
                }

                if (!values.province) {
                    errors.province = t("Required");
                }

                if (!values.zipcode) {
                    errors.zipcode = t("Required");
                }
            }

            return errors;
        },
        onSubmit: async (values) => {
            if(loading || !accessToken || !selectedGifts)
            {
                return;
            }

            setGetting(true);
            try {
                const service = new ApiService();
                const address = {
                    ...values
                }

                const requestGetGifts = await service.getGiftsItem(accessToken, selectedGifts.id, address);
                const isSuccess = requestGetGifts.data.status_code === 200;

                showNotification(
                    isSuccess ? "SUCCESS" : `Get gift failed (${requestGetGifts.data.status_code} - ${requestGetGifts.data.message})`,
                    isSuccess ? "success" : "error",
                    isSuccess ? 3000 : 5000
                )
            } catch (error) {
                console.error('Request failed', error);
            } finally {
                setGetting(false);
            }
        },
    });

    return (
        <div className="grid grid-cols-12 gap-3 items-center">
            <Modal
                isOpen={showPromotion}
                onClose={() => setShowPromotion(false)}
                title={selectedGifts ? `${selectedGifts.name}` : null}
            >
                <div className="my-3"></div>
                {
                    selectedGifts ? <div className="container max-w-[1100px] mx-auto">
                        <form className="grid grid-cols-12 gap-2" onSubmit={formik.handleSubmit}>
                            <div className={`col-span-12 md:col-span-3 lg:col-span-4`}>
                                <LazyLoadImage
                                    src={`${process.env.REACT_APP_API_URL}/gifts/${process.env.REACT_APP_WEBSITE_ID}/${selectedGifts.image}?v=${Math.floor(Date.now() / 1000)}`}
                                    alt={selectedGifts.name}
                                    className="w-full h-full object-cover rounded-lg"
                                    wrapperClassName="w-full h-full object-cover rounded-lg"
                                />
                            </div>
                            <div className={`col-span-12 md:col-span-9 lg:col-span-8`}>
                                <p className="text-lg text-blue-800">{t("Details")}: </p>
                                <DangerouslySetInnerHTML
                                    htmlContent={selectedGifts.description}
                                />
                                <hr/>
                                <p className="text-sm py-2">
                                    ราคา: {
                                        parseFloat(`${selectedGifts.amount}`).toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        })
                                    }&nbsp;
                                    {
                                        selectedGifts.type.toUpperCase()
                                    } 
                                </p>
                                
                                {
                                    (selectedGifts.need_address === 1) && <>
                                        <hr/>
                                        <div className="grid grid-cols-12 gap-2 py-2">
                                            <div className="col-span-12 md:col-span-12 lg:col-span-12">
                                                <p className="text-lg text-blue-800">กรุณากรอกที่อยู่</p>
                                            </div>
                                            <div className="col-span-12 md:col-span-6 lg:col-span-6">
                                                <label htmlFor='address' className="block text-sm font-medium text-gray-700">
                                                    บ้านเลขที่: 
                                                </label>
                                                <input 
                                                    id="address"
                                                    name="address"
                                                    className={`block w-full py-1 px-2 border ${formik.errors.address ? "border-red-500" : "border-gray-300"} rounded-md focus:ring focus:ring-indigo-100`}
                                                    value={formik.values.address}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    
                                                />
                                            </div>
                                            <div className="col-span-12 md:col-span-6 lg:col-span-6">
                                                <label htmlFor='moo' className="block text-sm font-medium text-gray-700">
                                                    หมู่ที่: 
                                                </label>
                                                <input 
                                                    id="moo"
                                                    name="moo"
                                                    className={`block w-full py-1 px-2 border ${formik.errors.moo ? "border-red-500" : "border-gray-300"} rounded-md focus:ring focus:ring-indigo-100`}
                                                    value={formik.values.moo}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    
                                                />
                                            </div>
                                            <div className="col-span-12 md:col-span-12 lg:col-span-12">
                                                <label htmlFor='khet' className="block text-sm font-medium text-gray-700">
                                                    เขต: 
                                                </label>
                                                <input 
                                                    id="khet"
                                                    name="khet"
                                                    className={`block w-full py-1 px-2 border ${formik.errors.khet ? "border-red-500" : "border-gray-300"} rounded-md focus:ring focus:ring-indigo-100`}
                                                    value={formik.values.khet}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    
                                                />
                                            </div>
                                            <div className="col-span-12 md:col-span-12 lg:col-span-12">
                                                <label htmlFor='kwang' className="block text-sm font-medium text-gray-700">
                                                    แขวง: 
                                                </label>
                                                <input 
                                                    id="kwang"
                                                    name="kwang"
                                                    className={`block w-full py-1 px-2 border ${formik.errors.kwang ? "border-red-500" : "border-gray-300"} rounded-md focus:ring focus:ring-indigo-100`}
                                                    value={formik.values.kwang}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    
                                                />
                                            </div>
                                            <div className="col-span-12 md:col-span-6 lg:col-span-6">
                                                <label htmlFor='province' className="block text-sm font-medium text-gray-700">
                                                    จังหวัด: 
                                                </label>
                                                <input 
                                                    id="province"
                                                    name="province"
                                                    className={`block w-full py-1 px-2 border ${formik.errors.province ? "border-red-500" : "border-gray-300"} rounded-md focus:ring focus:ring-indigo-100`}
                                                    value={formik.values.province}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    
                                                />
                                            </div>
                                            <div className="col-span-12 md:col-span-6 lg:col-span-6">
                                                <label htmlFor='zipcode' className="block text-sm font-medium text-gray-700">
                                                    รหัสไปรษณีย์: 
                                                </label>
                                                <input 
                                                    id="zipcode"
                                                    name="zipcode"
                                                    className={`block w-full py-1 px-2 border ${formik.errors.zipcode ? "border-red-500" : "border-gray-300"} rounded-md focus:ring focus:ring-indigo-100`}
                                                    value={formik.values.zipcode}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    
                                                />
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className={`col-span-12 lg:col-span-12`}>
                                <hr/>
                            </div>
                            <div className={`col-span-12 lg:col-span-12`}>
                                <Button isDisable={getting} className="w-full" type="submit">
                                    {t("Get_gifts")}
                                </Button>
                            </div>
                        </form>
                    </div> : null
                }
            </Modal>
            {
                loading ? <div className="col-span-12 lg:col-span-12">
                    <div className="flex justify-center">
                        <span className="inline-flex h-[10px] w-[10px] animate-ping rounded-full bg-sky-400 opacity-75"></span>
                    </div>
                </div> : gifts
                    .sort((a, b) => {
                        if(a.sort === null) return 1;
                        if(b.sort === null) return 0;
                        return a.sort - b.sort;
                    })
                    .map((promotion: GiftsListType, index: number) => (
                    <div
                        className="col-span-12 lg:col-span-6 rounded-lg shadow-md hover:scale-105 transition-all duration-300 cursor-pointer"
                        key={`promotion-key-${index}`}
                        onClick={() => {
                            setSelectedGifts(promotion);
                            setShowPromotion(true);
                        }}
                    >
                        <div className="flex flex-col">
                            <div>
                                <LazyLoadImage
                                    src={`${process.env.REACT_APP_API_URL}/gifts/${process.env.REACT_APP_WEBSITE_ID}/${promotion.image}?v=${Math.floor(Date.now() / 1000)}`}
                                    alt={promotion.name}
                                    className="w-full h-full object-cover rounded-t-lg"
                                    wrapperClassName="w-full h-full object-cover rounded-t-lg"
                                />
                            </div>
                            <div className="bg-white w-full">
                                <p className="text-center whitespace-nowrap overflow-hidden text-ellipsis">
                                    {promotion.name}
                                </p>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default Gifts;