import { useContext } from "react";
import AuthContext from "../../../contexts/AuthContext";
import NoRank from "./../../../assets/images/no-rank.webp";

const Dashboard = () => {
    const { memberData } = useContext(AuthContext);

    return (
        <div className="grid grid-cols-12 gap-3 items-center">
            {
                memberData ? <>
                    <div className="col-span-12 lg:col-span-12 flex flex-col items-center justify-center w-full">
                        <img src={memberData.rank_data ? (
                            `${process.env.REACT_APP_API_URL}/ranks/${process.env.REACT_APP_WEBSITE_ID}/${memberData.rank_data.image}`
                        ) : NoRank} alt={memberData.rank_data ? memberData.rank_data.name_en : "NORANK"} className="rounded-full max-w-[50px]" />
                        <span className="text-sm">
                            Rank: {memberData.rank_data ? memberData.rank_data.name_en : "NO RANK"}
                        </span>
                    </div>
                    <div className="col-span-12 lg:col-span-4">
                        <div>
                            <label htmlFor='uuid' className="block text-sm font-medium text-gray-700">
                                UUID
                            </label>
                            <input 
                                id="uuid"
                                name="uuid"
                                className={`block w-full p-2 border border-gray-300 rounded-md focus:ring focus:ring-indigo-200 text-sm`}
                                value={memberData.uuid}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="col-span-12 lg:col-span-4">
                        <div>
                            <label htmlFor='phone_number' className="block text-sm font-medium text-gray-700">
                                Phone number
                            </label>
                            <input 
                                id="phone_number"
                                name="phone_number"
                                className={`block w-full p-2 border border-gray-300 rounded-md focus:ring focus:ring-indigo-200 text-sm`}
                                value={memberData.username}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="col-span-12 lg:col-span-4">
                        <div>
                            <label htmlFor='username_application' className="block text-sm font-medium text-gray-700">
                                Username application
                            </label>
                            <input 
                                id="username_application"
                                name="username_application"
                                className={`block w-full p-2 border border-gray-300 rounded-md focus:ring focus:ring-indigo-200 text-sm`}
                                value={memberData.username_application}
                                readOnly
                            />
                        </div>
                    </div>
                </> : <div className="col-span-12 lg:col-span-12  flex flex-col items-center justify-center w-full">
                    <span className="inline-flex h-[10px] w-[10px] animate-ping rounded-full bg-sky-400 opacity-75"></span>
                </div>
            }
            
        </div>
    )
}

export default Dashboard;