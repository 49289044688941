import { useTranslation } from "react-i18next";

interface PropsType {
  tabs: string[];
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

const Tabs = ({ tabs, setActiveTab, activeTab }: PropsType) => {
    const { t }: { t: (key: string) => string } = useTranslation();
    return (
        <div className="w-full max-w-md mx-auto">
            <div className="flex border-b">
                {
                    tabs.map((tab) => (
                        <button
                            key={tab}
                            className={`py-2 px-4 text-sm font-medium focus:outline-none ${
                                activeTab === tab
                                ? "border-b-2 border-blue-500 text-blue-500"
                                : "text-gray-500"
                            }`}
                            onClick={() => setActiveTab(tab)}
                        >
                            {
                                t(`${tab.toUpperCase()}_tab`)
                            }
                        </button>
                    ))
                }
            </div>
        </div>
    );
};

export default Tabs;
