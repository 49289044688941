import { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../../contexts/AuthContext";
import { useFormik } from "formik";
import LoginContext from "../../../contexts/LoginContext";
import { ApiService } from "../../../services/api.service";
import showNotification from "../../_utils/showNotification";
import Button from "../../_tailwild/Button";
import { BankListType } from "../../../types/Bank";
import Select from "react-select";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const RegisterTab = () => {
    const { t }: { t: (key: string) => string } = useTranslation();
    const { i18n } = useTranslation();
    const usernameRef = useRef<HTMLInputElement>(null);
    const { activeTab, setLoginModalIsOpen, loginModalIsOpen } = useContext(LoginContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [bankSupport, setBankSupport] = useState<{value: string, label: string}[]>([]);
    const { accessToken, setCookiesAccessToken, setAccessToken } = useContext(AuthContext);
    const navigate = useNavigate();

    const query = useQuery();
    const by = query.get('by');
    const aff = query.get('aff');
    const cookies = new Cookies(null, { path: '/' });
    const [regBy, setRegBy] = useState<string | null>(null);
    const [affBy, setAffBy] = useState<string | null>(null);

    useEffect(() => {
        const ref = document.referrer;
        if(!cookies.get('referrer') || cookies.get('referrer') === '')
        {
            cookies.set('referrer', ref);
        }
    }, []);
    
    useEffect(() => {
        if(by)
        {
            cookies.set('reg_by', by);
            setRegBy(by);
        }
        else
        {
            setRegBy(!cookies.get('reg_by') ? null : cookies.get('reg_by'));
        }
    }, [by]);
    
    useEffect(() => {
        if(aff)
        {
            cookies.set('aff', aff);
            setAffBy(aff);
        }
        else
        {
            setAffBy(!cookies.get('aff') ? null : cookies.get('aff'));
        }
    }, [aff]);

    useEffect(() => {
        if(loginModalIsOpen && activeTab === 'register')
        {
            if(usernameRef && usernameRef.current)
            {
                usernameRef.current.focus()
            }
        }
    }, [loginModalIsOpen, activeTab]);

    useEffect(() => {
        (async () => {
            try {
                const service = new ApiService();
                const requestGetBankSupport = await service.getBankSupport();

                if(requestGetBankSupport.data.status_code === 200)
                {
                    setBankSupport(requestGetBankSupport.data.lists.map((bank: BankListType) => (
                        {
                            value: bank.bank_abbr,
                            label: (
                                <div className="flex items-center gap-2">
                                    <LazyLoadImage src={bank.bank_logo} alt={bank.bank_abbr} className="w-6 h-6 rounded-md" effect="blur" />
                                    <span>
                                        {
                                            i18n.language === 'th' ? `${bank.bank_name_th}` : `${bank.bank_name_en}`
                                        }
                                    </span>
                                </div>
                            )
                        }
                    )))
                }
            } catch (err) {
                console.error(err);
            }
        })();
    }, [i18n.language]);

    const formik = useFormik({
        initialValues: {
            phone: '',
            password: '',
            confirm_password: '',
            first_name: '',
            last_name: '',
            bank_abbr: '',
            bank_acc_number: '',
        },
        validate: (values) => {
            const errors: {
                phone?: string;
                password?: string;
                confirm_password?: string;
                first_name?: string;
                last_name?: string;
                bank_abbr?: string;
                bank_acc_number?: string;
            } = {};
			if (!values.phone) {
				errors.phone = 'Required';
			}
			if (!values.password) {
				errors.password = 'Required';
			}
            if (!values.confirm_password) {
				errors.confirm_password = 'Required';
			}
            if (values.password !== values.confirm_password) {
				errors.password = 'Password is not correctly';
				errors.confirm_password = 'Password is not correctly';
			}
            if (!values.first_name) {
				errors.first_name = 'Required';
			}
            if (!values.last_name) {
				errors.last_name = 'Required';
			}
            if (!values.bank_acc_number) {
				errors.bank_acc_number = 'Required';
			}

            return errors;
        },
        onSubmit: async (values) => {
            setLoading(true);
            
            try {
                const service = new ApiService();
                const requestRegister = await service.register(values.phone, values.password, values.first_name, values.last_name, values.bank_abbr, values.bank_acc_number, process.env.REACT_APP_WEBSITE_ID || 0, regBy, affBy);
                const responseCode = requestRegister.data.status_code;
                
                showNotification(
                    responseCode === 200 ? "Register successfully" : `Register failed (${responseCode} - ${requestRegister.data.message})`,
                    responseCode === 200 ? "success" : "error",
                    responseCode === 200 ? 3000 : 5000
                );

                if(responseCode === 200)
                {
                    setAccessToken?.(requestRegister.data.token);
                    setLoginModalIsOpen(false);
                    navigate('/login');
                }
            } catch (error) {
                console.error('Login failed', error);
            } finally {
                setLoading(false);
            }
        },
    });

    return (
        <form className="space-y-4" onSubmit={formik.handleSubmit}>
            <div>
                <label htmlFor='phone' className="block text-sm font-medium text-gray-700">{t('Phone_number')}</label>
                <input 
                    id="phone"
                    name="phone"
                    ref={usernameRef}
                    type="tel"
                    className={`block w-full p-2 border ${formik.errors.phone ? "border-red-500" : "border-gray-300"} rounded-md focus:ring focus:ring-indigo-200`}
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                {/* {
                    formik.errors.username && formik.touched.username && (
                        <div className="text-red-500 text-sm">{formik.errors.username}</div>
                    )
                } */}
            </div>
            <div>
                <label htmlFor='password' className="block text-sm font-medium text-gray-700">{t('Password')}</label>
                <input 
                    id="password" 
                    name="password" 
                    type="password"
                    className={`block w-full p-2 border ${formik.errors.password ? "border-red-500" : "border-gray-300"} rounded-md focus:ring focus:ring-indigo-200`}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                {/* {
                    formik.errors.password && formik.touched.password && (
                        <div className="text-red-500 text-sm">{formik.errors.password}</div>
                    )
                } */}
            </div>
            <div>
                <label htmlFor='confirm_password' className="block text-sm font-medium text-gray-700">{t('Confirm_password')}</label>
                <input 
                    id="confirm_password" 
                    name="confirm_password" 
                    type="password"
                    className={`block w-full p-2 border ${formik.errors.confirm_password ? "border-red-500" : "border-gray-300"} rounded-md focus:ring focus:ring-indigo-200`}
                    value={formik.values.confirm_password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                {/* {
                    formik.errors.password && formik.touched.password && (
                        <div className="text-red-500 text-sm">{formik.errors.password}</div>
                    )
                } */}
            </div>
            <div>
                <label htmlFor='first_name' className="block text-sm font-medium text-gray-700">{t('First_name')}</label>
                <input 
                    id="first_name" 
                    name="first_name" 
                    type="text"
                    className={`block w-full p-2 border ${formik.errors.first_name ? "border-red-500" : "border-gray-300"} rounded-md focus:ring focus:ring-indigo-200`}
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                {/* {
                    formik.errors.password && formik.touched.password && (
                        <div className="text-red-500 text-sm">{formik.errors.password}</div>
                    )
                } */}
            </div>
            <div>
                <label htmlFor='last_name' className="block text-sm font-medium text-gray-700">{t('Last_name')}</label>
                <input 
                    id="last_name" 
                    name="last_name" 
                    type="text"
                    className={`block w-full p-2 border ${formik.errors.last_name ? "border-red-500" : "border-gray-300"} rounded-md focus:ring focus:ring-indigo-200`}
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                {/* {
                    formik.errors.password && formik.touched.password && (
                        <div className="text-red-500 text-sm">{formik.errors.password}</div>
                    )
                } */}
            </div>
            <div>
                <label htmlFor='bank_abbr' className="block text-sm font-medium text-gray-700">{t('Bank')}</label>
                <Select
                    id="bank_abbr"
                    name="bank_abbr"
                    options={bankSupport}
                    className="w-full"
                    value={bankSupport.find((option) => option.value === formik.values.bank_abbr)}
                    onChange={(selectedOption) => formik.setFieldValue("bank_abbr", selectedOption?.value)}
                    onBlur={() => formik.setFieldTouched("bank_abbr", true)}
                />
                {/* {
                    formik.errors.password && formik.touched.password && (
                        <div className="text-red-500 text-sm">{formik.errors.password}</div>
                    )
                } */}
            </div>
            <div>
                <label htmlFor='bank_acc_number' className="block text-sm font-medium text-gray-700">{t('Bank_account_number')}</label>
                <input 
                    id="bank_acc_number" 
                    name="bank_acc_number" 
                    type="tel"
                    className={`block w-full p-2 border ${formik.errors.bank_acc_number ? "border-red-500" : "border-gray-300"} rounded-md focus:ring focus:ring-indigo-200`}
                    value={formik.values.bank_acc_number}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                {/* {
                    formik.errors.password && formik.touched.password && (
                        <div className="text-red-500 text-sm">{formik.errors.password}</div>
                    )
                } */}
            </div>
            <Button isDisable={loading} type="submit" className="w-full bg-indigo-600 text-white p-2 rounded-md hover:bg-indigo-700 disabled:bg-gray-400">
                {t('Register')}
            </Button>
        </form>
    )
}

export default RegisterTab;