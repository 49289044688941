import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import "./i18n";
// CSS
import './assets/css/index.css';
import 'react-notifications-component/dist/theme.css';

// COMPONENTS
import App from './App';
import Home from './components/Home/Home';
import Login from './components/Login/Login';
import MemberDataMain from './components/MemberData/MemberDataMain';
import GamesHome from './components/Games/GamesHome';
import DepositMain from './components/Deposit/DepositMain';
import Register from './components/Register/Register';

// CONTEXTS
import { AuthContextProvider } from './contexts/AuthContext';
import { LoginContextProvider } from './contexts/LoginContext';
import { GamesContextProvider } from './contexts/GamesContext';
import { WebsiteSettingsContextProvider } from './contexts/WebsiteSetting';
import { BankContextProvider } from './contexts/BankContext';
import { SidebarContextProvider } from './contexts/SidebarContext';
import { RewardCalendarContextProvider } from './contexts/RewardCalendarContext';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
    {
        element: <App />,
        children: [
            {
                path: "/", element: <Home />
            },
            {
                path: "/login", element: <Login />
            },
            {
                path: "/register", element: <Register />
            },
            {
                path: "/game/:category", element: <GamesHome />
            },
            {
                path: "/deposit", element: <DepositMain />
            },
            {
                path: "/member/", element: <MemberDataMain />
            },
            {
                path: "/member/:page", element: <MemberDataMain />
            },
            {
                path: "*", element: <Home />
            }
        ]
    }
]);

root.render(
    <AuthContextProvider>
        <RewardCalendarContextProvider>
            <SidebarContextProvider>
                <BankContextProvider>
                    <WebsiteSettingsContextProvider>
                        <LoginContextProvider>
                            <GamesContextProvider>
                                <React.StrictMode>
                                    <RouterProvider router={router} />
                                </React.StrictMode>
                            </GamesContextProvider>
                        </LoginContextProvider>
                    </WebsiteSettingsContextProvider>
                </BankContextProvider>
            </SidebarContextProvider>
        </RewardCalendarContextProvider>
    </AuthContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
