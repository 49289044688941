import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DepositTransactions from "./Data/DepositTransactions";
import WithdrawTransactions from "./Data/WithdrawTransactions";
import Deposit from "./Data/Deposit";
import Withdraw from "./Data/Withdraw";
import Promotions from "./Data/Promotions";
import AuthContext from "../../contexts/AuthContext";
import ReturnLoseCredit from "./Data/ReturnLoseCredit";
import Affiliate from "./Data/Affiliate";
import GetCoupon from "./Data/GetCoupon";
import Dashboard from "./Data/Dashboard";
import ContinuousDeposit from "./Data/ContinuousDeposit";
import Gifts from "./Data/Gifts";

interface MenusType {
    label: string,
    path: string
}

const menus: MenusType[] = [
    {
        label: 'Dashboard',
        path: '/member'
    },
    {
        label: 'Promotions',
        path: '/member/promotions'
    },
    {
        label: 'Deposit',
        path: '/member/deposit'
    },
    {
        label: 'Withdraw',
        path: '/member/withdraw'
    },
    {
        label: 'History deposit',
        path: '/member/history-deposit'
    },
    {
        label: 'History withdraw',
        path: '/member/history-withdraw'
    },
    {
        label: 'Return lose credit',
        path: '/member/return-lose-credit'
    },
    {
        label: 'Affiliate',
        path: '/member/affiliate'
    },
    {
        label: 'Coupon',
        path: '/member/coupon'
    },
    {
        label: 'Deposit continuous',
        path: '/member/deposit-continuous'
    },
    {
        label: 'Gifts',
        path: '/member/gifts'
    }
];
const MemberDataMain = () => {
    const navigate = useNavigate();
    const { accessToken, loading } = useContext(AuthContext);
    const { page } = useParams();

    useEffect(() => {
        if(!accessToken && !loading)
        {
            navigate('/login');
        }
    }, [accessToken, loading]);

    return (
        <div className="containe max-w-[1100px] mx-auto px-3">
            <div className="grid grid-cols-12 gap-2">
                <div className={`col-span-12 lg:col-span-3`}>
                    <div className="bg-white rounded-md shadow-sm">
                        {
                            menus.map((menu: MenusType, index: number) => (
                                <div
                                    key={`menu-member-${index}`}
                                    className={`w-full ${(menus.length - 1) !== index ?
                                        "border-b border-blue-300" : "rounded-b-md"
                                    } ${index === 0 && "rounded-t-md"} px-3 py-2 cursor-pointer hover:bg-gray-100`}
                                    onClick={() => navigate(menu.path)}
                                >
                                    <p className="text-sm hover:text-blue-800">
                                        {`${index + 1}. ${menu.label}`}
                                    </p>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className={`col-span-12 lg:col-span-9 bg-white rounded-md shadow-sm p-3 h-auto`}>
                    {
                        !page && <Dashboard />
                    }
                    {
                        page === 'deposit' && <Deposit />
                    }
                    {
                        page === 'withdraw' && <Withdraw />
                    }
                    {
                        page === 'history-deposit' && <DepositTransactions />
                    }
                    {
                        page === 'history-withdraw' && <WithdrawTransactions />
                    }
                    {
                        page === 'promotions' && <Promotions />
                    }
                    {
                        page === 'return-lose-credit' && <ReturnLoseCredit />
                    }
                    {
                        page === 'affiliate' && <Affiliate />
                    }
                    {
                        page === 'coupon' && <GetCoupon />
                    }
                    {
                        page === 'deposit-continuous' && <ContinuousDeposit />
                    }
                    {
                        page === 'gifts' && <Gifts />
                    }
                </div>
            </div>
        </div>
    )
}

export default MemberDataMain;