import { Outlet } from "react-router-dom";
import Header from "./components/_layouts/Header";
import LoginRegisterModal from "./components/_layouts/Modals/LoginRegisterModal";
// import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import SlideImages from "./components/SlideImages";
import Footer from "./components/_layouts/Footer";
import Sidebar from "./components/_layouts/Sidebar";
import Portal from "./components/Portal/Portal";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import RewardCalendar from "./components/_layouts/RewardCalendar";

const App = () => {
    return (
       <>
            <Portal id='portal-notification'>
                <ToastContainer />
            </Portal>
            <Header />
            <Sidebar />
            <RewardCalendar />
            <LoginRegisterModal />
            <div className="containe max-w-[1100px] mx-auto p-3">
                <SlideImages />
            </div>
            <Outlet/>
            <Footer />
       </>
    )
}

export default App;