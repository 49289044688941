import { useContext, useEffect, useState } from "react";
import { ApiService } from "../../../services/api.service";
import "react-lazy-load-image-component/src/effects/blur.css";
import AuthContext from "../../../contexts/AuthContext";
import Button from "../../_tailwild/Button";
import showNotification from "../../_utils/showNotification";
import { useTranslation } from "react-i18next";

const Affiliate = () => {
    const { t }: { t: (key: string) => string } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const { accessToken, memberData } = useContext(AuthContext);
    const [returnCreditTotal, setReturnCreditTotal] = useState<number>(0);

    useEffect(() => {
        loadReturnFriendCredit();
    }, [accessToken]);

    const loadReturnFriendCredit = async () => {
        if(loading || !accessToken)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const requestGetReturnFriendCredit = await service.requestGetReturnFriendCredit(accessToken);

            console.log(requestGetReturnFriendCredit.data);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const requestGetReturnFriendCredit = async () => {
        if(loading || !accessToken)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const requestGetReturnFriendCredit = await service.requestSubmitReturnFriendCredit(accessToken);

            showNotification(
                requestGetReturnFriendCredit.data.status_code === 200 ? "รับยอดเสียของเพื่อนเรียบร้อยแล้วค่ะ" : `เกิดข้อผิดพลาด (${requestGetReturnFriendCredit.data.status_code} - ${requestGetReturnFriendCredit.data.message})`,
                requestGetReturnFriendCredit.data.status_code === 200 ? "success" : "error",
                requestGetReturnFriendCredit.data.status_code === 200 ? 3000 : 5000
            )
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="grid grid-cols-12 gap-3 items-center">
            {
                loading ? <div className="col-span-12 lg:col-span-12">
                    <div className="flex justify-center">
                        <span className="inline-flex h-[10px] w-[10px] animate-ping rounded-full bg-sky-400 opacity-75"></span>
                    </div>
                </div> : <>
                    <div
                        className="col-span-12 lg:col-span-12"
                    >
                        <label htmlFor='totalReturnLoseCredit' className="block text-sm font-medium text-gray-700">
                            ลิงก์แนะนำเพื่อน
                        </label>
                        <input
                            id="totalReturnLoseCredit"
                            type="text"
                            className={`block w-full p-2 border border-gray-300 rounded-md focus:ring focus:ring-indigo-200`}
                            value={`${process.env.REACT_APP_BASE_URL}/register?aff=${memberData?.id}`}
                            readOnly={true}
                        />
                    </div>
                    <div
                        className="col-span-12 lg:col-span-12"
                    >
                        <Button onClick={() => {
                            navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URL}/register?aff=${memberData?.id}`);
                            showNotification(
                                "Copied to clipboard",
                                "success",
                                3000
                            )
                        }} isDisable={loading} type="button" className="w-full bg-indigo-600 text-white p-2 rounded-md hover:bg-indigo-700 disabled:bg-gray-400">
                            คัดลอก
                        </Button>
                    </div>
                    <div
                        className="col-span-12 lg:col-span-12"
                    >
                        <hr/>
                    </div>
                    <div
                        className="col-span-12 lg:col-span-12"
                    >
                        <label htmlFor='totalReturnLoseFriendCredit' className="block text-sm font-medium text-gray-700">
                            ยอดเสียของเพื่อนที่จะได้รับ
                        </label>
                        <input
                            id="totalReturnLoseFriendCredit"
                            type="text"
                            className={`block w-full p-2 border border-gray-300 rounded-md focus:ring focus:ring-indigo-200`}
                            value={parseFloat(`${returnCreditTotal}`).toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                            readOnly={true}
                        />
                    </div>
                    <div
                        className="col-span-12 lg:col-span-12"
                    >
                        <Button onClick={requestGetReturnFriendCredit} isDisable={loading} type="button" className="w-full bg-indigo-600 text-white p-2 rounded-md hover:bg-indigo-700 disabled:bg-gray-400">
                            รับยอดเสียของเพื่อน
                        </Button>
                    </div>
                </>
            }
        </div>
    )
}

export default Affiliate;