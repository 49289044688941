import { useContext, useEffect, useState } from "react";
import { ApiService } from "../../../services/api.service";
import "react-lazy-load-image-component/src/effects/blur.css";
import AuthContext from "../../../contexts/AuthContext";
import Button from "../../_tailwild/Button";
import showNotification from "../../_utils/showNotification";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

const GetCoupon = () => {
    const { t }: { t: (key: string) => string } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const { accessToken } = useContext(AuthContext);

    const formik = useFormik({
        initialValues: {
            codeCoupon: '',
        },
        validate: (values) => {
            const errors: {
                codeCoupon?: string;
            } = {};
			if (!values.codeCoupon) {
				errors.codeCoupon = t('Required');
			}

            return errors;
        },
        onSubmit: async (values) => {
            if(!accessToken || loading)
            {
                return;
            }

            setLoading(true);
            try {
                const service = new ApiService();
                const requestGetCoupon = await service.getCoupon(accessToken, values.codeCoupon);
                const responseCode = requestGetCoupon.data.status_code;
                const responseMessage = requestGetCoupon.data.message;

                showNotification(
                    responseCode === 200 ? "คุณรับคูปองเรียบร้อยแล้วค่ะ" : `รับคูปองไม่สำเร็จ (${responseCode} - ${responseMessage})`,
                    responseCode === 200 ? "success" : "error",
                    responseCode === 200 ? 3000 : 5000
                );
            } catch (error) {
                console.error('Get coupon failed', error);
            } finally {
                setLoading(false);
            }
        },
    });

    return (
        <div className="grid grid-cols-12 gap-3 items-center">
            {
                loading ? <div className="col-span-12 lg:col-span-12">
                    <div className="flex justify-center">
                        <span className="inline-flex h-[10px] w-[10px] animate-ping rounded-full bg-sky-400 opacity-75"></span>
                    </div>
                </div> : <div className="col-span-12 lg:col-span-12">
                    <form className="space-y-2" onSubmit={formik.handleSubmit}>
                        <div>
                            <label htmlFor='codeCoupon' className="block text-sm font-medium text-gray-700">
                                โค้ดคูปอง
                            </label>
                            <input 
                                id="codeCoupon"
                                name="codeCoupon"
                                className={`block w-full p-2 border ${formik.errors.codeCoupon ? "border-red-500" : "border-gray-300"} rounded-md focus:ring focus:ring-indigo-200`}
                                value={formik.values.codeCoupon}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                        <Button isDisable={loading} type="submit" className="w-full bg-indigo-600 text-white p-2 rounded-md hover:bg-indigo-700 disabled:bg-gray-400">
                            รับคูปอง
                        </Button>
                    </form>
                </div>
            }
        </div>
    )
}

export default GetCoupon;