import { useContext, useEffect, useState } from "react";
import { ApiService } from "../../../services/api.service";
import { ListPromotionsType } from "../../../types/Promotions";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Modal from "../../_utils/Modal";
import DangerouslySetInnerHTML from "../../_utils/DangerouslySetInnerHTML";
import AuthContext from "../../../contexts/AuthContext";
import Button from "../../_tailwild/Button";
import showNotification from "../../_utils/showNotification";
import { useTranslation } from "react-i18next";
import { ContinuousDepositType } from "../../../types/ContinuousDeposit";

const ContinuousDeposit = () => {
    const { t }: { t: (key: string) => string } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const { accessToken } = useContext(AuthContext);
    const [selectedContinuousDeposit, setSelectedContinuousDeposit] = useState<ContinuousDepositType | null>(null);
    const [showPromotion, setShowPromotion] = useState<boolean>(false);

    const [continuousDeposit, setContinuousDeposit] = useState<ContinuousDepositType[]>([]);

    useEffect(() => {
        getContinuousDeposit();
    }, [accessToken]);

    useEffect(() => {
        if(!selectedContinuousDeposit)
        {
            setSelectedContinuousDeposit(null);
        }
    }, [showPromotion])

    const getContinuousDeposit = async () => {
        if(loading || !accessToken)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const requestGetContinuousDeposit = await service.getContinuousDeposit(accessToken);

            if(requestGetContinuousDeposit.data.status_code === 200)
            {
                setContinuousDeposit(requestGetContinuousDeposit.data.continuous_deposit);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    const getContinuousDepositItem = async (promotion_id: number) => {
        if(!accessToken || loading)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const requestGetPromotion = await service.getContinuousDepositItem(accessToken, promotion_id);
            const responseCode = requestGetPromotion.data.status_code;
            const isSuccess = requestGetPromotion.data.status_code === 200;
            
            setShowPromotion(false);
            showNotification(
                
                isSuccess ? t("Get_continuous_deposit_successfully")
                : `Get countinounse deposit failed (${responseCode} - ${requestGetPromotion.data.message})`,
                isSuccess ? "success" : "error",
                isSuccess ? 3000 : 5000
            )
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="grid grid-cols-12 gap-3 items-center">
            <Modal
                isOpen={showPromotion}
                onClose={() => setShowPromotion(false)}
                title={selectedContinuousDeposit ? `${selectedContinuousDeposit.title}` : null}
            >
                <div className="my-3"></div>
                {
                    selectedContinuousDeposit ? <div className="container max-w-[1100px] mx-auto">
                        <div className="grid grid-cols-12 gap-2">
                            <div className={`col-span-12 md:col-span-3 lg:col-span-4`}>
                                <LazyLoadImage
                                    src={`${process.env.REACT_APP_API_URL}/continuous-deposit/${process.env.REACT_APP_WEBSITE_ID}/${selectedContinuousDeposit.image}?v=${Math.floor(Date.now() / 1000)}`}
                                    alt={selectedContinuousDeposit.title}
                                    className="w-full h-full object-cover rounded-lg"
                                    wrapperClassName="w-full h-full object-cover rounded-lg"
                                />
                            </div>
                            <div className={`col-span-12 md:col-span-9 lg:col-span-8`}>
                                <p className="text-md text-blue-800">{t("Playable_categories")}: </p>
                                {
                                    Object.entries(selectedContinuousDeposit.turnover_list)
                                    .filter(([_, value]) => value)
                                    .map(([key], index) => (
                                        <p className="text-sm">
                                            {index+1}. {key.toUpperCase()}&nbsp;
                                        </p>
                                    ))
                                }
                                <hr style={{ margin: 10 }}/>
                                <p className="text-sm">
                                    จำนวนครั้งฝากที่ต้องการ: {selectedContinuousDeposit.count_deposit}/{selectedContinuousDeposit.count} ครั้ง
                                </p>
                                <p className="text-sm">
                                    จำนวนเงินฝากที่ต้องการ: {
                                        parseFloat(`${selectedContinuousDeposit.total_deposit}`).toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        })
                                    }/{
                                        parseFloat(`${selectedContinuousDeposit.amount}`).toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        })
                                    } บาท
                                </p>
                            </div>
                            <div className={`col-span-12 lg:col-span-12`}>
                                <hr/>
                            </div>
                            <div className={`col-span-12 lg:col-span-12`}>
                                <Button isDisable={!selectedContinuousDeposit.passed} className="w-full" onClick={() => getContinuousDepositItem(selectedContinuousDeposit.id)}>
                                    {t("Get_continuous_deposit")}
                                </Button>
                            </div>
                        </div>
                    </div> : null
                }
            </Modal>
            {
                loading ? <div className="col-span-12 lg:col-span-12">
                    <div className="flex justify-center">
                        <span className="inline-flex h-[10px] w-[10px] animate-ping rounded-full bg-sky-400 opacity-75"></span>
                    </div>
                </div> : continuousDeposit
                    .sort((a, b) => {
                        if(a.sort === null) return 1;
                        if(b.sort === null) return 0;
                        return a.sort - b.sort;
                    })
                    .map((promotion: ContinuousDepositType, index: number) => (
                    <div
                        className="col-span-12 lg:col-span-6 rounded-lg shadow-md hover:scale-105 transition-all duration-300 cursor-pointer"
                        key={`promotion-key-${index}`}
                        onClick={() => {
                            setSelectedContinuousDeposit(promotion);
                            setShowPromotion(true);
                        }}
                    >
                        <div className="flex flex-col">
                            <div>
                                <LazyLoadImage
                                    src={`${process.env.REACT_APP_API_URL}/continuous-deposit/${process.env.REACT_APP_WEBSITE_ID}/${promotion.image}?v=${Math.floor(Date.now() / 1000)}`}
                                    alt={promotion.title}
                                    className="w-full h-full object-cover rounded-t-lg"
                                    wrapperClassName="w-full h-full object-cover rounded-t-lg"
                                />
                            </div>
                            <div className="bg-white w-full">
                                <p className="text-center whitespace-nowrap overflow-hidden text-ellipsis">
                                    {promotion.title}
                                </p>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default ContinuousDeposit;