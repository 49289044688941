import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// ต้องเรียกใช้ toast.configure() ในจุดเริ่มต้นของแอป
const showNotification = (
	message: string,
	type: 'success' | 'error' | 'info' | 'warning' = 'info',
	duration: number = 3000
) => {
	toast(message, {
		type,
		position: "top-right",
		autoClose: duration,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		theme: "light",
	});
};

export default showNotification;
