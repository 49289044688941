import { useContext, useEffect, useState } from "react";
import { ApiService } from "../../../services/api.service";
import "react-lazy-load-image-component/src/effects/blur.css";
import AuthContext from "../../../contexts/AuthContext";
import Button from "../../_tailwild/Button";
import showNotification from "../../_utils/showNotification";
import { useTranslation } from "react-i18next";

const ReturnLoseCredit = () => {
    const { t }: { t: (key: string) => string } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const { accessToken } = useContext(AuthContext);
    const [returnLoseCredit, setReturnLoseCredit] = useState<number>(0);

    useEffect(() => {
        getLoseCredit();
    }, [accessToken]);

    const getLoseCredit = async () => {
        if(!accessToken || loading)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const requestGetReturnLoseCredit = await service.getTotalReturnLoseCredit(accessToken);

            if(requestGetReturnLoseCredit.data.status_code !== 200)
            {
                return showNotification(
                    `Cannot get return lost credit (${requestGetReturnLoseCredit.data.status_code} - ${requestGetReturnLoseCredit.data.message})`,
                    "error",
                    5000
                )
            }

            setReturnLoseCredit(requestGetReturnLoseCredit.data.returnCreditTotal);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    const requestGetLoseCredit = async () => {
        if(!accessToken || loading)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const requestGetReturnLoseCredit = await service.requestGetTotalReturnLoseCredit(accessToken);
            const isSuccess = requestGetReturnLoseCredit.data.status_code === 200;
            
            showNotification(
                isSuccess ? "รับยอดเสียคืนเรียบร้อยแล้ว" : `Cannot get return lost credit (${requestGetReturnLoseCredit.data.status_code} - ${requestGetReturnLoseCredit.data.message})`,
                isSuccess ? "success" : "error",
                isSuccess ? 3000 : 5000
            );

            setTimeout(() => {
                window.location.reload();
            }, isSuccess ? 3000 : 5000);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="grid grid-cols-12 gap-3 items-center">
            {
                loading ? <div className="col-span-12 lg:col-span-12">
                    <div className="flex justify-center">
                        <span className="inline-flex h-[10px] w-[10px] animate-ping rounded-full bg-sky-400 opacity-75"></span>
                    </div>
                </div> : <>
                    <div
                        className="col-span-12 lg:col-span-12"
                    >
                        <label htmlFor='totalReturnLoseCredit' className="block text-sm font-medium text-gray-700">
                            ยอดเสียที่สามารถรับคืน
                        </label>
                        <input
                            id="totalReturnLoseCredit"
                            type="text"
                            className={`block w-full p-2 border border-gray-300 rounded-md focus:ring focus:ring-indigo-200`}
                            value={parseFloat(`${returnLoseCredit}`).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            readOnly={true}
                        />
                    </div>
                    <div
                        className="col-span-12 lg:col-span-12"
                    >
                        <Button onClick={requestGetLoseCredit} isDisable={loading} type="button" className="w-full bg-indigo-600 text-white p-2 rounded-md hover:bg-indigo-700 disabled:bg-gray-400">
                            รับยอดเสียคืน
                        </Button>
                    </div>
                </>
            }
        </div>
    )
}

export default ReturnLoseCredit;