import { useFormik } from "formik";
import Button from "../../_tailwild/Button";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../contexts/AuthContext";
import { ApiService } from "../../../services/api.service";
import showNotification from "../../_utils/showNotification";
import WebsiteSettingsContext from "../../../contexts/WebsiteSetting";
import { WebsiteSettingsType } from "../../../types/Settings";
import { useTranslation } from "react-i18next";

const Withdraw = () => {
    const { t }: { t: (key: string) => string } = useTranslation();
    const { accessToken } = useContext(AuthContext);
    const { websiteSetting } = useContext(WebsiteSettingsContext);
    const [loading, setLoading] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: {
            amount: ''
        },
        validate: (values) => {
            const errors: {
                amount?: string;
            } = {};
			if (!values.amount) {
				errors.amount = 'Required';
			}

            return errors;
        },
        onSubmit: async (values) => {
            if(loading || !accessToken || !websiteSetting || !websiteSetting.auto_withdraw_payment_id)
            {
                return;
            }

            setLoading(true);
            try {
                const service = new ApiService();
                const requestCreatePayout = await service.requestCreatePayout(accessToken, websiteSetting.auto_withdraw_payment_id, values.amount);
                const responseCode = requestCreatePayout.data.status_code;

                showNotification(
                    responseCode === 200 ? t("Request_create_withdraw_successfully")
                    : `Request create withdraw failed (${responseCode} - ${requestCreatePayout.data.message})`,
                    responseCode === 200 ? "success" : "error",
                    responseCode === 200 ? 3000 : 5000
                );
            } catch (error) {
                console.error('Request create payout failed', error);
            } finally {
                setLoading(false);
            }
        },
    });

    return (
        <div className="grid grid-cols-12 gap-2 items-center">
            <form className="col-span-12 lg:col-span-12" onSubmit={formik.handleSubmit}>
                <div className="flex items-center gap-1 w-full">
                    <label htmlFor='amount' className="block text-sm font-medium text-gray-700">
                        {t('Amount')}: 
                    </label>
                    <input 
                        id="amount"
                        name="amount"
                        className={`block w-full px-2 border ${formik.errors.amount ? "border-red-500" : "border-gray-300"} rounded-md focus:ring focus:ring-indigo-200`}
                        value={formik.values.amount}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder={t('Amount')}
                    />
                </div>
            </form>
            {/* <div className="col-span-12 lg:col-span-6">
                {
                    formik.errors.amount && formik.touched.amount && (
                        <div className="text-red-500 text-sm">{formik.errors.amount}</div>
                    )
                }
            </div> */}
            <div className="col-span-12 lg:col-span-12">
                <hr/>
            </div>
            {
                [20, 50, 100, 150, 300, 500, 1000, 2500, 5000].map((item: number, index: number) => (
                    <div className="col-span-4 lg:col-span-4" key={`auto-fill-${item}-${index}`}>
                        <button
                            className="block w-full px-2 py-1 text-sm border rounded-md bg-gray-200 hover:bg-gray-300 disabled:opacity-50 button-sm"
                            onClick={() => formik.setFieldValue("amount", `${item}`)}
                        >
                            {
                                parseFloat(`${item}`).toLocaleString('en-US', {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0
                                })
                            }
                        </button>
                    </div>
                ))
            }
            <div className="col-span-12 lg:col-span-12">
                <Button
                    onClick={formik.handleSubmit}
                    className="block w-full"
                    isDisable={loading || !websiteSetting}
                >
                    {t('Withdraw')}	
                </Button>
            </div>
        </div>
    )
}

export default Withdraw;