import moment from "moment";
import { useContext, useEffect, useState } from "react";
import RewardCalendarContext from "../../contexts/RewardCalendarContext";
import Modal from "../_utils/Modal";
import Coins from "./../../assets/images/coins.png";
import Diamond from "./../../assets/images/diamond.png";
import X from "./../../assets/images/x.png";
import AuthContext from "../../contexts/AuthContext";
import { ApiService } from "../../services/api.service";
import { DailyRewardsType } from "../../types/DailyRewards";
import showNotification from "../_utils/showNotification";
import LoginContext from "../../contexts/LoginContext";

const months = [
    { number: 1, thai: "มกราคม", english: "January" },
    { number: 2, thai: "กุมภาพันธ์", english: "February" },
    { number: 3, thai: "มีนาคม", english: "March" },
    { number: 4, thai: "เมษายน", english: "April" },
    { number: 5, thai: "พฤษภาคม", english: "May" },
    { number: 6, thai: "มิถุนายน", english: "June" },
    { number: 7, thai: "กรกฎาคม", english: "July" },
    { number: 8, thai: "สิงหาคม", english: "August" },
    { number: 9, thai: "กันยายน", english: "September" },
    { number: 10, thai: "ตุลาคม", english: "October" },
    { number: 11, thai: "พฤศจิกายน", english: "November" },
    { number: 12, thai: "ธันวาคม", english: "December" }
];

const RewardCalendar = () => {
    const { isOpenRewardModal, setIsOpenRewardModal } = useContext(RewardCalendarContext);
    const { setLoginModalIsOpen } = useContext(LoginContext);
    const [loading, setLoading] = useState<boolean>(false);
    const { accessToken } = useContext(AuthContext);
    const today = moment();
    const daysInMonth = today.daysInMonth();
    const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
    const [dailyRewards, setDailyRewards] = useState<DailyRewardsType[]>([]);

    useEffect(() => {
        getDailyRewards();
    }, []);

    const getDailyRewards = async () => {
        if(loading)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const requestGetDailyRewards = await service.getDailyRewards();

            if(requestGetDailyRewards.data.status_code === 200)
            {
                setDailyRewards(requestGetDailyRewards.data.rewards);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    const requestGetDailyRewardsItem = async (date: string) => {
        if(!accessToken)
        {
            setIsOpenRewardModal(false);
            setLoginModalIsOpen(true);
            return;
        }

        if(loading || !date)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const requestGetDailyRewards = await service.getDailyRewardsItem(accessToken, date);

            if(requestGetDailyRewards.data.status_code === 200)
            {
                setIsOpenRewardModal(false);
            }

            showNotification(
                requestGetDailyRewards.data.status_code === 200 ? "SUCCESS" : `${requestGetDailyRewards.data.status_code} - ${requestGetDailyRewards.data.message}`,
                requestGetDailyRewards.data.status_code === 200 ? "success" : "error",
                requestGetDailyRewards.data.status_code === 200 ? 3000 : 5000
            );
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Modal
            isOpen={isOpenRewardModal}
            onClose={() => setIsOpenRewardModal(false)}
            title={`Daily rewards of ${months[months.findIndex((month) => month.number === moment().month() + 1)].thai}`}
        >
            <div className="p-2">
                <div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-6 gap-1">
                    {
                        days.map((day) => (
                            <div
                                key={day}
                                className={`flex flex-col items-center justify-center w-30 h-30 text-white cursor-pointer hover:scale-105 transition duration-300 ${
                                    moment().format("DD") !== `${day}`.padStart(2, '0') && "filter grayscale"
                                }`}
                                onClick={() => {
                                    if(dailyRewards.findIndex(daily => moment(daily.date).format("DD") === `${day}`.padStart(2, '0')) !== -1)
                                    {
                                        requestGetDailyRewardsItem(moment(
                                            dailyRewards[dailyRewards.findIndex(daily => moment(daily.date).format("DD") === `${day}`.padStart(2, '0'))].date
                                        ).format("YYYY-MM-DD"));
                                    }
                                    else
                                    {
                                        showNotification("Not found daily rewards", "error", 5000);
                                    }
                                }}
                            >
                                <div className="bg-gray-600 w-full h-full rounded-t-lg flex items-center justify-center flex-col p-1">
                                    {
                                        dailyRewards.findIndex(daily => moment(daily.date).format("DD") === `${day}`.padStart(2, '0')) !== -1 ? <>
                                            <img
                                                src={dailyRewards[dailyRewards.findIndex(daily => moment(daily.date).format("DD") === `${day}`.padStart(2, '0'))].type === 'credit' ?
                                                    Coins : Diamond
                                                }
                                                className="max-w-[50px] max-h-[50px]"
                                                alt="Coins"
                                            />
                                            <span className="text-sm">{
                                                dailyRewards[dailyRewards.findIndex(daily => moment(daily.date).format("DD") === `${day}`.padStart(2, '0'))].type.toUpperCase()
                                            } x{
                                                dailyRewards[dailyRewards.findIndex(daily => moment(daily.date).format("DD") === `${day}`.padStart(2, '0'))].amount
                                            }</span>
                                        </> : <>
                                            <img src={X} className="max-w-[50px] max-h-[50px]" alt="X" />
                                            <span className="text-sm">No reward</span>
                                        </>
                                    }
                                </div>
                                <div className="bg-gray-200 w-full rounded-b-lg flex items-center justify-center flex-col text-black p-[2px]">
                                    <span className="text-sm">วันที่ {day}</span>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </Modal>
    );
};

export default RewardCalendar;